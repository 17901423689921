body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.example::-webkit-scrollbar {
  display: none;
}

.example {
  -ms-overflow-style: none; 
}
.btn-warning {
  background-color: rgb(243, 134, 33) !important;
  border-color: rgb(243, 127, 19) !important;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning:visited,
.btn-warning:focus {
  background-color: rgb(243, 127, 19) !important;
  border-color: rgb(243, 127, 19) !important;
}